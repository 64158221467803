/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';

const Blog = () => {
    const sliderRef = useRef(null);
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/get-all-blog`);
                setBlogs(response?.data?.blogs);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, [apiUrl]); // Include apiUrl in the dependency array

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'dd MMMM yyyy'); // Format as "13 August 2024"
    };

    const getExcerpt = (htmlContent, length) => {
        const sanitizedHTML = DOMPurify.sanitize(htmlContent); // Sanitize the HTML
        const textOnly = sanitizedHTML.replace(/<\/?[^>]+(>|$)/g, ""); // Strip out HTML tags
        return textOnly.length > length ? textOnly.substring(0, length) + '...' : textOnly;
    };

    return (
        <div>
            <div className='pt-5 my-20' style={{ backgroundColor: "#F8F8F8" }}>
                <div className="max-w-screen-2xl mx-auto px-3 py-10 relative">
                    <div>
                        <h2 className='text-4xl font-bold p-10'>Our Latest Blogs</h2>
                    </div>
                    <div className="flex justify-between items-center">
                        {/* Navigation Buttons */}
                        <div className="absolute top-0 right-0 mt-3">
                            <button
                                className="bg-white mx-5 hover:bg-gray-300 text-gray-800 font-bold py-2 px-2 rounded-full"
                                onClick={() => sliderRef.current.slickPrev()}
                            >
                                <svg className="h-8 w-8 text-[#586893]" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <polyline points="15 6 9 12 15 18" />
                                </svg>
                            </button>
                            <button
                                className="bg-white mx-5 hover:bg-gray-300 text-gray-800 font-bold py-2 px-2 rounded-full"
                                onClick={() => sliderRef.current.slickNext()}
                            >
                                <svg className="h-8 w-8 text-[#586893]" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <polyline points="9 6 15 12 9 18" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    {/* Slider Section */}
                    {loading ? (
                        <h1>Loading ...</h1>
                    ) : (
                        <Slider ref={sliderRef} {...settings}>
                            {blogs?.reverse().map(item => (
                                <div key={item.id} className='px-4 focus:outline-none focus:border-none focus:shadow-none py-5'>
                                    <div className="col-span-12 md:col-span-3 px-9 shadow-md p-7 rounded-lg bg-white hover:scale-105 transition duration-500 cursor-pointer h-[407px]">
                                        <img src={item.image} className='w-full h-[200px] rounded-[25px]' alt="" />
                                        <div className="p-1 pt-4">
                                            <h3 className='font-bold'>{item.title}</h3>
                                            <h6 className='text-[#212C65] text-sm font-bold pb-2'>{formatDate(item.created_at)}</h6>
                                            <div className='text-justify text-sm' dangerouslySetInnerHTML={{ __html: getExcerpt(item.desc, 100) }} />
                                            <a href="#" className='text-sm pt-5 underline font-bold'>Read Blogs</a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Blog;
