import React from 'react';
import OurPorcess from "../../asset/image/sectionImage/ourprocess.png"
const OurProcess = () => {
    return (
        <div  className='mt-5'>
            <div className="max-w-screen-2xl mx-auto px-3 py-20">
                <div className="text-center max-w-screen-xl mx-auto">
                    <h2 className='text-4xl font-bold text-[#FE9901] pb-5'>Our Process</h2>
                    <p className='text-lg'>
                        For your business growth your main focus is generating more customers and increasing your sales. As your business evolves, managing digital marketing can get overwhelming. Business Marketing Agency is here to make it easy. Our experienced team helps you navigate the complexities of online marketing, so you can focus on growing your business.
                    </p>
                    <p className='text-lg font-bold tracking-widest pt-5'>  </p>
                    <img src={OurPorcess} className='w-full' alt="" />
                </div>

                <div class="grid grid-cols-12 gap-7 text-justify">
                    <div class="col-span-12 md:col-span-4 p-9 shadow-lg bg-white transform transition duration-500 hover:scale-105 cursor-pointer">
                        <h3 className='text-2xl text-cardBG font-bold py-2'>Marketing Discovery</h3>
                        <p className='leading-loose text-sm'>We start with a Free Marketing Analysis, including an Online Presence, SEO, and Website Speed Report. We meet to understand your business, products, pricing, and target audience while identifying marketing gaps. Together, we define your growth goals and timeline.</p>
                    </div>
                    <div class="col-span-12 md:col-span-4 p-9 bg-white shadow-lg transform transition duration-500 hover:scale-105 cursor-pointer">
                        <h3 className='text-2xl text-cardBG font-bold py-2'>Marketing Plan</h3>
                        <p className='leading-loose text-sm'>With a clear understanding of your position, we’ll create a personalized marketing strategy designed to deliver tangible results—more leads and more business opportunities. Based on extensive research into your industry, target audience, and business goals, our plan is actionable, elevating your brand and driving outcomes.</p>
                    </div>
                    <div class="col-span-12 md:col-span-4 p-9 bg-white shadow-lg transform transition duration-500 hover:scale-105 cursor-pointer">
                        <h3 className='text-2xl text-cardBG font-bold py-2'>Marketing Success</h3>
                        <p className='leading-loose text-sm'>Finally, we implement your tailored strategy. You start seeing increased leads, foot traffic, and conversions—leading to rising profits. Our team monitors progress continuously and meets with you weekly to ensure your business stays ahead in the fast-changing digital landscape.</p>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default OurProcess;