import React, { useState } from 'react';
import OnlineBusinessImage from "../../asset/image/sectionImage/image 674.png"
import backgroundImage from '../../asset/image/background/FlagBG.png';
import { Button, Modal } from "flowbite-react";

const OnlineBusiness = () => {


    const backgroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%', // Adjust the height as needed
        width: '100%',
        margin: 'auto auto',
    };


    function onOpenModal() {
        // Load the external script when opening the modal
        const targetElement = document.getElementById('my-div');
        const script = document.createElement('script');
        script.id = "__v_snapshot_widget";
        script.src = "https://www.cdnstyles.com/static/snapshot_widget/v1/snapshot.widget.js";
        script.setAttribute("data", "eyJhcGlLZXkiOiJkNzA0MzcwNi1mMWNjLTQ2MWUtODM1MC04MmRmMjI4NzdjM2QiLCJiYWNrZ3JvdW5kQ29sb3IiOiIjMWE3MmQwIiwiYm9yZGVyQ29sb3IiOiIjRkZGRkZGIiwiYm9yZGVyUmFkaXVzIjoyLCJidXR0b25Db2xvciI6IiNmZTk5MDEiLCJidXR0b25Gb250Q29sb3IiOiIjRkZGRkZGIiwiYnV0dG9uVGV4dCI6IkZyZWUgTWFya2V0aW5nIEFuYWx5c2lzIiwiY29uZmlybWF0aW9uQm9keVRleHQiOiJPbmNlIHRoZSByZXBvcnRzIGFyZSByZWFkeSwgd2UnbGwgc2VuZCB5b3VyIHJlcG9ydHMgdG8gdGhlIGVtYWlsIGFkZHJlc3MgeW91IGhhdmUgcHJvdmlkZWQuIiwiY29uZmlybWF0aW9uVGl0bGVUZXh0IjoiV2UncmUgZ2VuZXJhdGluZyB5b3VyIHJlcG9ydCEiLCJjb25zZW50SW5mb3JtYXRpb25UZXh0IjoiIiwiZGF0YVByb3RlY3Rpb25Qb2xpY3lUZXh0IjoiIiwiZHJvcFNoYWRvdyI6dHJ1ZSwiZW1haWxOb3RpZmljYXRpb25BZ3JlZW1lbnRUZXh0IjoiIiwicGFydG5lcklkIjoiRVowVSIsInByaW1hcnlGb250Q29sb3IiOiIjZmZmZmZmIiwic2Vjb25kYXJ5Rm9udENvbG9yIjoiI2ZmZmZmZiIsInRpdGxlVGV4dCI6IkhvdyBpcyBteSBidXNpbmVzcyBwZXJmb3JtaW5nIG9ubGluZT8iLCJ1cmwiOiJodHRwczovL3NuYXBzaG90LXdpZGdldC1wcm9kLmFwaWdhdGV3YXkuY28vIiwid2lkZ2V0SWQiOiJTTkFQU0hPVC1XSURHRVQtM2ZmZGNmYmYtNTQyNy00NGZkLWFkODItODg5YmU2MWE1ZGNjIiwid2lkdGgiOjB9");
        const donechild = targetElement.appendChild(script);

        setTimeout(() => {
            document.getElementById('close').style.display = "block";
        }, 1000)

    }

    const closeModal = () => {
        window.location.reload();
        setTimeout(() => {
          document.getElementById('close').style = "display:none"
        }, 1000)
    }



    return (
        <div style={backgroundStyle} className='relative'>
            <div className='max-w-screen-2xl mx-auto p-4 pt-10 my-10'>
                <div className='bg-slate-50 opacity-75 rounded-[50px] grid grid-cols-12 gap-4 lg:p-10 lg:mb-10 lg:mt-5'>
                    <div class="p-4 col-span-12 md:col-span-6 lg:pt-0">
                        <img src={OnlineBusinessImage} className='w-full' alt="Online Business" />
                    </div>
                    <div class="p-4 col-span-12 md:col-span-6 text-center">
                        <div className="lg:p-9 lg:px-20">
                            <h2 className='text-xl font-bold py-2'>How Visible Is Your Business Online?</h2>
                            <p className='text-lg'>Get a Free Online Presence Report of your performance across the internet.</p>

                            <button onClick={onOpenModal} class="flex items-center justify-center gap-2 px-6 py-3 mt-6 bg-[#FE9901] hover:bg-yellow-400 text-white font-bold rounded-full transition mx-auto w-fit mx-auto">
                                <span>Free Marketing Analysis</span>
                                <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                </svg>
                            </button>

                            <div id="my-div">
                                {/* The widget will be injected here */}
                            </div>

                            <button onClick={closeModal} id='close' class="flex items-center justify-center gap-2 p-2 bg-[#1A72D0] hover:bg-blue-500 text-white font-bold rounded-full transition mx-auto w-fit mx-auto">
                                <svg class="h-8 w-8 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <line x1="18" y1="6" x2="6" y2="18" />  <line x1="6" y1="6" x2="18" y2="18" /></svg>
                            </button>
                        </div>

                    </div>
                </div>

            </div>


        </div>
    );
};

export default OnlineBusiness;