import React from 'react';
import MarketingConsultantHeader from '../../component/Header/MarketingConsultantHeader';
import MarketingPageSection from '../../asset/image/sectionImage/image.png';

import Footer from '../../component/Footer/Footer';
import CardImage1 from '../../asset/image/icon/21.png'
import CardImage2 from '../../asset/image/icon/2.png'
import CardImage3 from '../../asset/image/icon/22.png'
import OurProcess from '../../component/OurProcess/OurProcess';
import ServiceCard from '../../component/ServiceCard/ServiceCard';
import { Link } from 'react-router-dom';
const MarketingConsultant = () => {
    return (
        <div>
            {/* header serction */}
            <MarketingConsultantHeader />

            {/* marketing consultant section */}
            <div className="bg-gray-100 py-20">
                <div className="grid grid-cols-12 max-w-screen-2xl mx-auto">
                    <div class="col-span-12 lg:col-span-5 flex justify-between pt-40 lg:pt-0">
                        <img src={MarketingPageSection} class="h-auto w-max" alt="Header Image" />
                    </div>
                    <div class="p-4 col-span-12 lg:col-span-7">
                        <h2 class="text-3xl md:text-4xl lg:text-5xl font-bold py-2 leading-tight lg:leading-normal">
                            Transform Your Business with a  <span class="text-[#FE9901]"> Marketing Consultant</span>
                        </h2>

                        <p class="text-lg md:text-xl py-2 lg:pr-40">
                            Discover the power of hiring a marketing consultant to take your business to new heights. Say goodbye to the complexities of employing a full-time marketing team and embrace the expertise of a dedicated marketing consultant who will work closely with you to develop and execute a winning marketing strategy. With a wealth of industry knowledge, a fresh perspective, and a tailored approach, our marketing consultants are equipped to drive growth, increase brand visibility, and deliver exceptional results. Let us be your strategic partner in navigating the ever-changing digital landscape and unlocking the true potential of your business.
                        </p>
                        <a href="#" class="flex items-center justify-center gap-2 px-6 py-3 mt-6 bg-[#FE9901] hover:bg-yellow-400 text-white font-bold rounded-full transition w-fit">
                            <span>Schedule A Call</span>
                            <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                            </svg>
                        </a>
                    </div>



                </div>
            </div>

            {/* Our process */}
            <OurProcess />

            <div className="bg-gray-100 py-5">
                <div className='max-w-screen-2xl mx-auto my-10'>
                    <div className="p-5">
                        <h2 className='text-4xl font-bold'><span class="text-[#FE9901]">Our Service Models</span></h2>
                        <div className="lg:flex items-center justify-between gap-40">
                            <p className='text-xl pt-5'>We guarantee tangible results from our digital marketing services. We will use <br /> well-researched strategies and the latest digital tools to drive results for your business.</p>
                            <Link to="/" class="flex items-center justify-center gap-2 px-6 py-3 mt-6 bg-[#FE9901] hover:bg-yellow-400 text-white font-bold rounded-full transition w-fit">
                                <span>Get Started</span>
                                <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3">

                        <ServiceCard CardImage={CardImage1} title="DIY – Do It Yourself" text="Empower your business with our innovative DIY tools and dashboard. Seamlessly manage advertising, online reviews, and more—all from one intuitive platform, giving you full control over your marketing." />
                        <ServiceCard CardImage={CardImage2} title="DIT – Do It Together" text="Partner with us to co-create a winning strategy. We’ll help build your website, elevate your social media presence, and fuel your growth, while you stay focused on your core business." />
                        <ServiceCard CardImage={CardImage3} title="DFY – Done For You" text="Leave everything to us. With our expert marketing team and tailored solutions, we handle all your digital marketing needs, driving your business toward success." />

                    </div>
                </div>
            </div>


            <Footer />
        </div>
    );
};

export default MarketingConsultant;