/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Footer from '../../component/Footer/Footer';
import Navbar from '../../component/Header/Navbar';
import backgroundImage from '../../asset/image/background/headerBg.png';
import ArrowRight from '../../component/icon/ArrowRight';
import App from '../../asset/image/sectionImage/App.png';
import ListingManagementHeader from '../../asset/image/Listingmanagement/Group 1707486542.png';
import ListingManagementServices from '../../asset/image/Listingmanagement/Group 1707486516.png';
import { Link } from 'react-router-dom';
import backgroundImage2 from '../../asset/image/background/image.png';

const ListingsManagementServices = () => {

    const backgroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '70%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '80%', // Adjust the height as needed
        width: '100%',
        margin: 'auto auto',
    };

    const [activeIndex, setActiveIndex] = useState(null); // Start with all sections closed

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqs = [
        {
            question: "Does Listing Sync Pro support service area businesses?",
            answer: "Listing Sync Pro does support service area businesses; however, GPS sources will not be synced as there is no physical location for the business."
        },
        {
            question: "What business information does Listing Sync Pro update?",
            answer: "Listing Sync Pro does support service area businesses; however, GPS sources will not be synced as there is no physical location for the business."
        },
        {
            question: "How long will it take for my clients’ listings to update?",
            answer: "Listing Sync Pro does support service area businesses; however, GPS sources will not be synced as there is no physical location for the business."
        },
        {
            question: "Which business listing sites does Listing Sync Pro update?",
            answer: "Listing Sync Pro does support service area businesses; however, GPS sources will not be synced as there is no physical location for the business."
        },
        {
            question: "How many Listing sites are synced?",
            answer: "Listing Sync Pro does support service area businesses; however, GPS sources will not be synced as there is no physical location for the business."
        },
      
    ];

    const backgroundStyle2 = {
        backgroundImage: `url(${backgroundImage2})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%', // Adjust the height as needed
        width: '100%',
        margin: 'auto auto',
    };


    return (
        <>
            {/* header section */}
            <div style={backgroundStyle}>
                <Navbar />

                <div class="max-w-screen-2xl mx-auto p-4 grid grid-cols-12 gap-1 pt-40 pb-20">

                    <div class="p-4 col-span-12 lg:col-span-7">
                        <h2 class="text-2xl md:text-4xl lg:text-5xl font-bold py-2 leading-tight lg:leading-normal">
                        Listings <span class="text-[#FE9901]"> Management Services </span>
                        </h2>
                        <h6 class="text-lg md:text-xl font-bold py-2 text-blue-900">
                          Take Control of Your Business Listings with Listings Management
                        </h6>
                        <h6 class="text-lg md:text-xl font-bold py-2 text-blue-900">
                           Business Marketing Agency » Listings Management
                        </h6>
                        <p class="text-lg md:text-xl py-2 lg:pr-40">
                            With our listings management service, you can focus on what you do best while we safeguard your online presence and enhance your local search visibility.
                        </p>
                        <a href="#" class="flex items-center justify-center gap-2 px-6 py-3 mt-6 bg-[#FE9901] hover:bg-yellow-400 text-white font-bold rounded-full transition w-fit">
                            <span>Schedule a Consultation</span>
                            <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                            </svg>
                        </a>
                    </div>


                    <div class="p-4 col-span-12 lg:col-span-5 flex justify-center lg:justify-end">
                        <img src={ListingManagementHeader} class="w-full h-auto max-w-lg" alt="Header Image" />
                    </div>
                </div>
            </div>

            {/* marketing consultant section */}
            <div className="py-20 bg-gray-100">
                <div className="grid grid-cols-12 max-w-screen-2xl mx-auto">
                    <div class="col-span-12 lg:col-span-5 flex justify-between pt-40 lg:pt-0">
                        <img src={ListingManagementServices} class="h-auto w-[90%]" alt="Header Image" />
                    </div>
                    <div class="p-4 col-span-12 lg:col-span-7 text-justify">

                        <p class="text-lg py-2 lg:pr-40">
                            Ensure accuracy and consistency across the web with our comprehensive listings management service. Your business listings play a crucial role in attracting customers and driving local search visibility. However, managing and updating listings across various online directories can be time-consuming and prone to errors. Our expert team will handle the tedious task of keeping your business information up to date, ensuring that potential customers can easily find you.
                        </p>
                        <p class="text-lg py-2 lg:pr-40">
                           From business name, address, and phone number (NAP) to website URL and business hours, we’ll take care of it all. With our listings management service, you can focus on what you do best while we safeguard your online presence and enhance your local search visibility.
                        </p>
                       
                        <a href="#" class="flex items-center justify-center gap-2 px-6 py-3 mt-6 bg-[#FE9901] hover:bg-yellow-400 text-white font-bold rounded-full transition w-fit">
                            <span>CHECK MY BUSINESS LISTING ACCURACY</span>
                            <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                            </svg>
                        </a>
                    </div>



                </div>
            </div>


            {/*  Reputation Management Plans */}
            <div className="py-5">
                <div className='max-w-screen-2xl mx-auto my-10'>
                    <div className="p-5 pb-8 text-center">
                        <h2 className='text-4xl font-bold'>Our Affordable<span class="text-[#FE9901]">Listings Management Plans</span></h2>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                        {/* seo card */}
                        <div class={`bg-[#F9EFEE] m-4 p-5 marketing-card shadow-xl transform transition duration-500 hover:scale-105`}>
                            <div className='p-4 pb-0'>
                                <h2 className='text-lg font-bold py-2 text-center'>Listing Builder Kick-start your online presence</h2>
                                <h1 className='text-xl font-bold text-[#586793] py-2 text-center'>$19 / Month</h1>
                                <ul className='list-circle text-sm py-2 text-gray-500' style={{listStyle: 'square'}}>
                                    <li>Free sync to four listing sources (Google Business Profile, Facebook, Instagram, Twitter)</li>
                                    <li>My Listing (free landing page)</li>
                                    <li>Google Business Profile Insights</li>
                                    <li>Update and edit business information to sync across sources from one platform</li>
                                </ul>
                            </div>
                            <div className="text-center pb-5">
                                <Link href="#" class="mx-auto cursor-pointer flex items-center justify-center gap-2 px-6 py-3 mt-6 bg-[#FE9901] hover:bg-yellow-400 text-white font-bold rounded-full transition w-fit">
                                    <span>Get Started</span>
                                    <ArrowRight />
                                </Link>
                            </div>
                        </div>
                        {/* seo card */}

                        <div class={`bg-[#F9EFEE] m-4 p-5 marketing-card shadow-xl transform transition duration-500 hover:scale-105`}>
                            <div className='p-4 pb-0'>
                                <h2 className='text-lg font-bold py-2 text-center'>Listing Sync Pro Get accurate, get found</h2>
                                <h1 className='text-xl font-bold text-[#586793] py-2 text-center'>$50 / Month</h1>
                                <ul className='list-circle text-sm py-2 text-gray-500' style={{listStyle: 'square'}}>
                                    <li>Everything in Listing Builder</li>
                                    <li>Sync status reporting</li>
                                    <li>Protection from third-party changes</li>
                                    <li>Publish business data almost instantaneously to a list of high traffic sources</li>
                                    <li>Control of how you appear online</li>
                                    <li>Ability to manage duplicate suppression</li>
                                </ul>
                            </div>
                            <div className="text-center pb-5">
                                <Link href="#" class="mx-auto cursor-pointer flex items-center justify-center gap-2 px-6 py-3 mt-6 bg-[#FE9901] hover:bg-yellow-400 text-white font-bold rounded-full transition w-fit">
                                    <span>Get Started</span>
                                    <ArrowRight />
                                </Link>
                            </div>
                        </div>
                       
                     
                    </div>
             
                </div>
            </div>

       

            {/* Get Business App */}
            <div className="py-20 bg-gray-100" style={backgroundStyle2}>
                <div className="grid grid-cols-12 max-w-screen-2xl mx-auto">
                    <div class="col-span-12 lg:col-span-5 flex justify-between pt-40 lg:pt-0">
                        <img src={App} class="h-auto w-[100%]" alt="Header Image" />
                    </div>
                    <div class="p-4 col-span-12 lg:col-span-7">
                        <h2 class="text-3xl md:text-4xl lg:text-5xl font-bold py-2 leading-tight lg:leading-normal">
                            Get Business App
                        </h2>

                        <p class="text-lg py-2 lg:pr-40">
                            Everything a local business needs to succeed end-to-end.
                        </p>
                        <p class="text-lg py-2 lg:pr-40">
                            You have the vision. You have the passion. Now you need all the tools to help your business grow. That’s where we come in. From a complete digital strategy to conversion-building products and customer communication, we’ve got you covered.                        </p>
                        <p class="text-lg py-2 lg:pr-40">
                            You have the vision. You have the passion. Now you need all the tools to help your business grow. That’s where we come in. From a complete digital strategy to conversion-building products and customer communication, we’ve got you covered.
                        </p>
                        <a href="#" class="flex items-center justify-center gap-2 px-6 py-3 mt-6 bg-[#FE9901] hover:bg-yellow-400 text-white font-bold rounded-full transition w-fit">
                            <span>Create My Free Account</span>
                            <ArrowRight />
                        </a>
                    </div>



                </div>
            </div>

            {/* Frequently Asked Question */}
            <div className='py-9 pb-20'>

                <div className="max-w-screen-2xl mx-auto px-3">
                    <div className="text-center max-w-screen-lg mx-auto pt-20 pb-10">
                        <h2 className='text-4xl font-bold text-[#FE9901]'>Frequently Asked Questions</h2>
                    </div>

                    <div id="accordion-color" data-accordion="collapse" className='mx-5'>
                        {faqs.map((faq, index) => (
                            <div key={index} className="mb-4">
                                <h2 id={`accordion-color-heading-${index}`}>
                                    <button
                                        type="button"
                                        className={`flex items-center justify-between w-full p-5 font-medium text-left border-gray-200 focus:outline-none ${activeIndex === index ? 'bg-cardBG text-white' : 'bg-[#F8F8F8] text-gray-800'} dark:border-gray-700 gap-3`}
                                        onClick={() => toggleAccordion(index)}
                                        style={{ borderRadius: activeIndex === index ? "25px 25px 0 0" : "25px" }}
                                    >
                                        <span className='text-lg'>{faq.question}</span>
                                        <svg
                                            data-accordion-icon
                                            className={`w-6 h-6 transition-transform duration-200 ${activeIndex === index ? 'rotate-180' : ''}`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M6 9l6 6 6-6"
                                            />
                                        </svg>
                                    </button>
                                </h2>
                                {activeIndex === index && (
                                    <div id={`accordion-color-body-${index}`} className="p-5 pt-0 dark:border-gray-700 bg-cardBG text-white" style={{ borderRadius: "0 0 25px 25px" }}>
                                        <p className="mb-2 text-sm leading-[30px]">{faq.answer}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default ListingsManagementServices;