/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import Footer from '../../component/Footer/Footer';
import Navbar from '../../component/Header/Navbar';
import backgroundImage from '../../asset/image/background/headerBg.png';
import backgroundImage2 from '../../asset/image/background/image.png';
import MarketingPageHeader from '../../asset/image/sectionImage/MarketingServices.png';
import SEOServices from '../../asset/image/group/SEOServices.png';
import ArrowRight from '../../component/icon/ArrowRight';

import SeoTask1 from '../../asset/image/seotask/Image1.png';
import SeoTask2 from '../../asset/image/seotask/SeoTask2.png';
import SeoTask3 from '../../asset/image/seotask/Image3.png';
import SeoTask4 from '../../asset/image/seotask/Image4.png';
import SeoTask5 from '../../asset/image/seotask/Image5.png';
import SeoTask6 from '../../asset/image/seotask/Image6.png';
import SeoTask7 from '../../asset/image/seotask/Image7.png';
import App from '../../asset/image/sectionImage/App.png';
import { Link } from 'react-router-dom';

const MarketingServices = () => {

    const backgroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '70%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '80%', // Adjust the height as needed
        width: '100%',
        margin: 'auto auto',
    };

    const [activeIndex, setActiveIndex] = useState(null); // Start with all sections closed

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqs = [
        {
            question: "What is SEO?",
            answer: "SEO, or Search Engine Optimization, is the practice of optimizing a website to improve its visibility and ranking in search engine results pages (SERPs). In simpler terms, it involves making changes to your website design and content to make it more attractive to search engines like Google, Bing, and Yahoo. <br/> The goal of SEO is to increase organic (non-paid) traffic to your website by ensuring that it appears higher in relevant search results. This is achieved through various techniques, including keyword research, on-page optimization, link building, and technical optimization."
        },
        {
            question: "How does keyword research impact SEO?",
            answer: "SEO, or Search Engine Optimization, is the practice of optimizing a website to improve its visibility and ranking in search engine results pages (SERPs). In simpler terms, it involves making changes to your website design and content to make it more attractive to search engines like Google, Bing, and Yahoo. <br/> The goal of SEO is to increase organic (non-paid) traffic to your website by ensuring that it appears higher in relevant search results. This is achieved through various techniques, including keyword research, on-page optimization, link building, and technical optimization."
        },
        {
            question: "How long does it take to see results from SEO?",
            answer: "SEO, or Search Engine Optimization, is the practice of optimizing a website to improve its visibility and ranking in search engine results pages (SERPs). In simpler terms, it involves making changes to your website design and content to make it more attractive to search engines like Google, Bing, and Yahoo. <br/> The goal of SEO is to increase organic (non-paid) traffic to your website by ensuring that it appears higher in relevant search results. This is achieved through various techniques, including keyword research, on-page optimization, link building, and technical optimization."
        },
        {
            question: "Is SEO a one-time process or an ongoing effort?",
            answer: "SEO, or Search Engine Optimization, is the practice of optimizing a website to improve its visibility and ranking in search engine results pages (SERPs). In simpler terms, it involves making changes to your website design and content to make it more attractive to search engines like Google, Bing, and Yahoo. <br/> The goal of SEO is to increase organic (non-paid) traffic to your website by ensuring that it appears higher in relevant search results. This is achieved through various techniques, including keyword research, on-page optimization, link building, and technical optimization."
        },
        {
            question: "What is the difference between organic and paid search results?",
            answer: "SEO, or Search Engine Optimization, is the practice of optimizing a website to improve its visibility and ranking in search engine results pages (SERPs). In simpler terms, it involves making changes to your website design and content to make it more attractive to search engines like Google, Bing, and Yahoo. <br/> The goal of SEO is to increase organic (non-paid) traffic to your website by ensuring that it appears higher in relevant search results. This is achieved through various techniques, including keyword research, on-page optimization, link building, and technical optimization."
        },
        {
            question: "Can SEO help improve my website's ranking on search engines other than Google?",
            answer: "SEO, or Search Engine Optimization, is the practice of optimizing a website to improve its visibility and ranking in search engine results pages (SERPs). In simpler terms, it involves making changes to your website design and content to make it more attractive to search engines like Google, Bing, and Yahoo. <br/> The goal of SEO is to increase organic (non-paid) traffic to your website by ensuring that it appears higher in relevant search results. This is achieved through various techniques, including keyword research, on-page optimization, link building, and technical optimization."
        },
    ];

    const backgroundStyle2 = {
        backgroundImage: `url(${backgroundImage2})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%', // Adjust the height as needed
        width: '100%',
        margin: 'auto auto',
    };

    return (
        <>
            {/* header section */}
            <div style={backgroundStyle}>
                <Navbar />

                <div class="max-w-screen-2xl mx-auto p-4 grid grid-cols-12 gap-1 pt-40 pb-20">

                    <div class="p-4 col-span-12 lg:col-span-7">
                        <h2 class="text-2xl md:text-4xl lg:text-5xl font-bold py-2 leading-tight lg:leading-normal">
                            SEO Services Melbourne
                            Dominate Search Results with<span class="text-[#FE9901]"> Our Cutting-Edge SEO Solutions </span>
                        </h2>
                        <h6 class="text-lg md:text-xl font-bold py-2 text-blue-900">
                            Business Marketing Agency » SEO Services Melbourne
                        </h6>
                        <p class="text-lg md:text-xl py-2 lg:pr-40">
                            Enhance your website’s visibility in search engine results and drive organic traffic. Our SEO services and strategies include keyword research, on-page optimization, link building, and content optimization to improve your website’s search rankings.
                        </p>
                        <Link to="/" class="flex items-center justify-center gap-2 px-6 py-3 mt-6 bg-[#FE9901] hover:bg-yellow-400 text-white font-bold rounded-full transition w-fit">
                            <span>Schedule a Consultation</span>
                            <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                            </svg>
                        </Link>
                    </div>


                    <div class="p-4 col-span-12 lg:col-span-5 flex justify-center lg:justify-end">
                        <img src={MarketingPageHeader} class="w-full h-auto max-w-lg" alt="Header Image" />
                    </div>
                </div>
            </div>

            {/* marketing consultant section */}
            <div className="py-20">
                <div className="grid grid-cols-12 max-w-screen-2xl mx-auto">
                    <div class="col-span-12 lg:col-span-5 flex justify-between pt-40 lg:pt-0">
                        <img src={SEOServices} class="h-auto w-[90%]" alt="Header Image" />
                    </div>
                    <div class="p-4 col-span-12 lg:col-span-7">
                        <h2 class="text-3xl md:text-4xl lg:text-5xl font-bold py-2 leading-tight lg:leading-normal">
                            SEO Services <span class="text-[#FE9901]"> Melbourne</span>
                        </h2>

                        <p class="text-lg py-2 lg:pr-40">
                            Boost your online presence and drive targeted organic traffic to your website with our comprehensive Search Engine Optimization (SEO) services. Our team of experts employs a data-driven approach to optimize your website for search engines. Through in-depth keyword research, we identify the most relevant and high-performing keywords for your business.
                        </p>
                        <p class="text-lg py-2 lg:pr-40">
                            Our on-page optimization techniques ensure that your website’s meta tags, headings, and content align with search engine best practices. Additionally, we implement effective link-building strategies to enhance your website’s authority and credibility. With regular performance monitoring and analysis, we continuously refine our strategies to improve your search rankings and increase organic visibility.
                        </p>
                        <p class="text-lg py-2 lg:pr-40">
                            We have the most affordable SEO packages in Melbourne. Click on the link below 👇🏼👇🏼 to get your FREE SEO Audit Report.
                        </p>
                        <Link to="/" class="flex items-center justify-center gap-2 px-6 py-3 mt-6 bg-[#FE9901] hover:bg-yellow-400 text-white font-bold rounded-full transition w-fit">
                            <span>GENERATE FREE SEO AUDIT REPORT</span>
                            <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                            </svg>
                        </Link>
                    </div>



                </div>
            </div>


            {/* SEO Plans */}
            <div className="py-5 bg-gray-100">
                <div className='max-w-screen-2xl mx-auto my-10'>
                    <div className="p-5 text-center">
                        <h2 className='text-4xl font-bold'>Our Simple &<span class="text-[#FE9901]">Affordable SEO Plans</span></h2>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
                        {/* seo card */}
                        <div class={`bg-[#F9EFEE] m-4 p-5 marketing-card shadow-xl transform transition duration-500 hover:scale-105`}>
                            <div className='p-4 pb-0 text-center'>
                                <h2 className='text-lg font-bold py-2'>DIY SEO</h2>
                                <h1 className='text-xl font-bold text-[#586793] py-2'>$149 / month</h1>
                                <p className='text-sm py-2 text-gray-500'>Take control of your own SEO by scanning and following the tailored and easy-to-complete monthly actions.</p>
                            </div>
                            <div className="text-center pb-5">
                                <Link to="/" class="mx-auto cursor-pointer flex items-center justify-center gap-2 px-6 py-3 mt-6 bg-[#FE9901] hover:bg-yellow-400 text-white font-bold rounded-full transition w-fit">
                                    <span>Get Started</span>
                                    <ArrowRight />
                                </Link>
                            </div>
                        </div>
                        {/* seo card */}
                        <div class={`bg-[#F9EFEE] m-4 p-5 marketing-card shadow-xl transform transition duration-500 hover:scale-105`}>
                            <div className='p-4 pb-0 text-center'>
                                <h2 className='text-lg font-bold py-2'>DIY SEO</h2>
                                <h1 className='text-xl font-bold text-[#586793] py-2'>$149 / month</h1>
                                <p className='text-sm py-2 text-gray-500'>Take control of your own SEO by scanning and following the tailored and easy-to-complete monthly actions.</p>
                            </div>
                            <div className="text-center pb-5">
                                <a href="#" class="mx-auto cursor-pointer flex items-center justify-center gap-2 px-6 py-3 mt-6 bg-[#FE9901] hover:bg-yellow-400 text-white font-bold rounded-full transition w-fit">
                                    <span>Get Started</span>
                                    <ArrowRight />
                                </a>
                            </div>
                        </div>
                        {/* seo card */}
                        <div class={`bg-[#F9EFEE] m-4 p-5 marketing-card shadow-xl transform transition duration-500 hover:scale-105`}>
                            <div className='p-4 pb-0 text-center'>
                                <h2 className='text-lg font-bold py-2'>DIY SEO</h2>
                                <h1 className='text-xl font-bold text-[#586793] py-2'>$149 / month</h1>
                                <p className='text-sm py-2 text-gray-500'>Take control of your own SEO by scanning and following the tailored and easy-to-complete monthly actions.</p>
                            </div>
                            <div className="text-center pb-5">
                                <a href="#" class="mx-auto cursor-pointer flex items-center justify-center gap-2 px-6 py-3 mt-6 bg-[#FE9901] hover:bg-yellow-400 text-white font-bold rounded-full transition w-fit">
                                    <span>Get Started</span>
                                    <ArrowRight />
                                </a>
                            </div>
                        </div>
                        {/* seo card */}
                        <div class={`bg-[#F9EFEE] m-4 p-5 marketing-card shadow-xl transform transition duration-500 hover:scale-105`}>
                            <div className='p-4 pb-0 text-center'>
                                <h2 className='text-lg font-bold py-2'>DIY SEO</h2>
                                <h1 className='text-xl font-bold text-[#586793] py-2'>$149 / month</h1>
                                <p className='text-sm py-2 text-gray-500'>Take control of your own SEO by scanning and following the tailored and easy-to-complete monthly actions.</p>
                            </div>
                            <div className="text-center pb-5">
                                <a href="#" class="mx-auto cursor-pointer flex items-center justify-center gap-2 px-6 py-3 mt-6 bg-[#FE9901] hover:bg-yellow-400 text-white font-bold rounded-full transition w-fit">
                                    <span>Get Started</span>
                                    <ArrowRight />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="pt-5">
                        <p className='text-gray-500 pb-2'>It’s said that Content is King and SEO is the Queen of your website. Both are interdependent and important to have successful SEO results. SEO is the key to achieve higher visibility, ranking and organic traffic. Most people do not visit the second page of search results. SEO optimized content is the one of many ways to rank on the first page.</p>
                        <p className='text-gray-500'>As a leading SEO Agency in Melbourne, we offer SEO optimized website content and blogs to keep your website up-to-date with the latest industry updates.</p>
                    </div>
                </div>
            </div>

            {/* SEO Tasks */}

            <div className="py-20">
                <div className="p-5 text-center">
                    <h2 className='text-4xl font-bold'>SEO Tasks To<span class="text-[#FE9901]">Increase Search Rankings</span></h2>
                </div>

                {/*Seo Task 1 */}
                <div className="grid grid-cols-12 max-w-screen-2xl mx-auto py-6">
                    <div class="p-4 col-span-12 lg:col-span-7">
                        <h2 class="text-2xl font-bold py-2 leading-tight lg:leading-normal">
                            Brand Analysis
                        </h2>
                        <p class="text-lg text-gray-500">
                            We perform an in-depth market analysis of your brand and compare it to your nearest competitors. Based on the results, we will create a customised SEO plan for your brand.
                        </p>
                    </div>

                    <div class="col-span-12 lg:col-span-5 flex justify-between pt-40 lg:pt-0">
                        <img src={SeoTask1} class="h-auto w-[90%]" alt="Header Image" />
                    </div>
                </div>

                {/*Seo Task 2 */}
                <div className="grid grid-cols-12 max-w-screen-2xl mx-auto py-6">

                    <div class="col-span-12 lg:col-span-5 flex justify-between pt-40 lg:pt-0">
                        <img src={SeoTask2} class="h-auto w-[90%]" alt="Header Image" />
                    </div>

                    <div class="p-4 col-span-12 lg:col-span-7">
                        <h2 class="text-2xl font-bold py-2 leading-tight lg:leading-normal">
                            Keyword Research
                        </h2>
                        <p class="text-lg text-gray-500">
                            We will research and list the best short-tailed and long-tailed keywords relevant to your brand. SEO rich keywords are extremely important for an effective content strategy. This helps to increase website traffic and Google rankings.
                        </p>
                    </div>

                </div>


                {/*Seo Task 3 */}
                <div className="grid grid-cols-12 max-w-screen-2xl mx-auto py-6">
                    <div class="p-4 col-span-12 lg:col-span-7">
                        <h2 class="text-2xl font-bold py-2 leading-tight lg:leading-normal">
                            Website Monitoring
                        </h2>
                        <p class="text-lg text-gray-500">
                            Next we focus on your website, making sure that it is fast, easy to navigate and provides a great user experience on all devices. Google puts great emphasis on this to boost your search rankings.
                        </p>
                    </div>

                    <div class="col-span-12 lg:col-span-5 flex justify-between pt-40 lg:pt-0">
                        <img src={SeoTask3} class="h-auto w-[90%]" alt="Header Image" />
                    </div>
                </div>

                {/*Seo Task 4 */}
                <div className="grid grid-cols-12 max-w-screen-2xl mx-auto py-6">

                    <div class="col-span-12 lg:col-span-5 flex justify-between pt-40 lg:pt-0">
                        <img src={SeoTask4} class="h-auto w-[90%]" alt="Header Image" />
                    </div>

                    <div class="p-4 col-span-12 lg:col-span-7">
                        <h2 class="text-2xl font-bold py-2 leading-tight lg:leading-normal">
                            Quality Content
                        </h2>
                        <p class="text-lg text-gray-500">
                            We will continuosly add new SEO optimized content, and optimize the website in line with Google’s algorithm guidelines. Thesestrategies will consistently increase your Search rankings not only in Google, but also in other search engines.
                        </p>
                    </div>

                </div>

                {/*Seo Task 5*/}
                <div className="grid grid-cols-12 max-w-screen-2xl mx-auto py-6">
                    <div class="p-4 col-span-12 lg:col-span-7">
                        <h2 class="text-2xl font-bold py-2 leading-tight lg:leading-normal">
                            Quality Content
                        </h2>
                        <p class="text-lg text-gray-500">
                            We will continuosly add new SEO optimized content, and optimize the website in line with Google’s algorithm guidelines. Thesestrategies will consistently increase your Search rankings not only in Google, but also in other search engines.
                        </p>
                    </div>

                    <div class="col-span-12 lg:col-span-5 flex justify-between pt-40 lg:pt-0">
                        <img src={SeoTask5} class="h-auto w-[90%]" alt="Header Image" />
                    </div>
                </div>

                {/*Seo Task 6*/}
                <div className="grid grid-cols-12 max-w-screen-2xl mx-auto py-6">
                    <div class="col-span-12 lg:col-span-5 flex justify-between pt-40 lg:pt-0">
                        <img src={SeoTask6} class="h-auto w-[90%]" alt="Header Image" />
                    </div>
                    <div class="p-4 col-span-12 lg:col-span-7">
                        <h2 class="text-2xl font-bold py-2 leading-tight lg:leading-normal">
                            Technical SEO
                        </h2>
                        <p class="text-lg text-gray-500">
                            We will consistently work on On-Page, Off-Page and Technical SEO by creating new blogs, guest posts, news articles, press releases and social media shares to increase traffic and build audience engagement.
                        </p>
                    </div>


                </div>

                {/*Seo Task 7*/}
                <div className="grid grid-cols-12 max-w-screen-2xl mx-auto py-6">
                    <div class="p-4 col-span-12 lg:col-span-7">
                        <h2 class="text-2xl font-bold py-2 leading-tight lg:leading-normal">
                            Monthly Reports
                        </h2>
                        <p class="text-lg text-gray-500">
                            Use our Business App to view your weekly and monthly online presence results. Connect your Google and Facebook accounts to view all the results in one place. You will be able to see the consistent increase in visitors, leads and sales.
                        </p>
                    </div>

                    <div class="col-span-12 lg:col-span-5 flex justify-between pt-40 lg:pt-0">
                        <img src={SeoTask7} class="h-auto w-[90%]" alt="Header Image" />
                    </div>
                </div>

            </div>

            {/* Get Business App */}
            <div className="py-20 bg-gray-100" style={backgroundStyle2}>
                <div className="grid grid-cols-12 max-w-screen-2xl mx-auto">
                    <div class="col-span-12 lg:col-span-5 flex justify-between pt-40 lg:pt-0">
                        <img src={App} class="h-auto w-[100%]" alt="Header Image" />
                    </div>
                    <div class="p-4 col-span-12 lg:col-span-7">
                        <h2 class="text-3xl md:text-4xl lg:text-5xl font-bold py-2 leading-tight lg:leading-normal">
                            Get Business App
                        </h2>

                        <p class="text-lg py-2 lg:pr-40">
                            Everything a local business needs to succeed end-to-end.
                        </p>
                        <p class="text-lg py-2 lg:pr-40">
                            You have the vision. You have the passion. Now you need all the tools to help your business grow. That’s where we come in. From a complete digital strategy to conversion-building products and customer communication, we’ve got you covered.                        </p>
                        <p class="text-lg py-2 lg:pr-40">
                            You have the vision. You have the passion. Now you need all the tools to help your business grow. That’s where we come in. From a complete digital strategy to conversion-building products and customer communication, we’ve got you covered.
                        </p>
                        <Link to="#" class="flex items-center justify-center gap-2 px-6 py-3 mt-6 bg-[#FE9901] hover:bg-yellow-400 text-white font-bold rounded-full transition w-fit">
                            <span>Create My Free Account</span>
                            <ArrowRight />
                        </Link>
                    </div>



                </div>
            </div>

            {/* Frequently Asked Question */}
            <div className='py-9 pb-20'>

                <div className="max-w-screen-2xl mx-auto px-3">
                    <div className="text-center max-w-screen-lg mx-auto pt-20 pb-10">
                        <h2 className='text-4xl font-bold text-[#FE9901]'>Frequently Asked Questions</h2>
                    </div>

                    <div id="accordion-color" data-accordion="collapse" className='mx-5'>
                        {faqs.map((faq, index) => (
                            <div key={index} className="mb-4">
                                <h2 id={`accordion-color-heading-${index}`}>
                                    <button
                                        type="button"
                                        className={`flex items-center justify-between w-full p-5 font-medium text-left border-gray-200 focus:outline-none ${activeIndex === index ? 'bg-cardBG text-white' : 'bg-[#F8F8F8] text-gray-800'} dark:border-gray-700 gap-3`}
                                        onClick={() => toggleAccordion(index)}
                                        style={{ borderRadius: activeIndex === index ? "25px 25px 0 0" : "25px" }}
                                    >
                                        <span className='text-lg'>{faq.question}</span>
                                        <svg
                                            data-accordion-icon
                                            className={`w-6 h-6 transition-transform duration-200 ${activeIndex === index ? 'rotate-180' : ''}`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M6 9l6 6 6-6"
                                            />
                                        </svg>
                                    </button>
                                </h2>
                                {activeIndex === index && (
                                    <div id={`accordion-color-body-${index}`} className="p-5 pt-0 dark:border-gray-700 bg-cardBG text-white" style={{ borderRadius: "0 0 25px 25px" }}>
                                        <p className="mb-2 text-sm leading-[30px]">{faq.answer}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default MarketingServices;