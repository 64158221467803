import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../../component/Header/Navbar';
import Footer from '../../component/Footer/Footer';
import BlogPageHeadder from '../../asset/image/blog/BlogPageHeadder.png';
import Logo from '../../asset/image/logo.png';
import DOMPurify from 'dompurify';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

const BlogPage = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    const apiUrl = process.env.REACT_APP_API_URL;

    
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/get-all-blog`);
                setBlogs(response?.data?.blogs);
            } catch (err) {
                console.log(err)
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);




    const getExcerpt = (htmlContent, length) => {
        const sanitizedHTML = DOMPurify.sanitize(htmlContent); // Sanitize the HTML
        const textOnly = sanitizedHTML.replace(/<\/?[^>]+(>|$)/g, ""); // Strip out HTML tags
        return textOnly.length > length ? textOnly.substring(0, length) + '...' : textOnly;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'dd MMMM yyyy'); // Format as "13 August 2024"
    };

    return (
        <>
            <Navbar />
            {/* header section */}
            <section className='max-w-screen-2xl mx-auto mt-[130px]'>
                <img src={BlogPageHeadder} className='w-full' alt="Blog Page Header" />
            </section>

            {/* main blog section */}
            <main className='max-w-screen-2xl mx-auto py-20'>
                <section className="grid grid-cols-12 gap-7">
                    <div className="col-span-12 lg:col-span-7">
                        <div className="grid grid-cols-12 gap-8">
                            {
                               loading ? <h1 className='text-center text-2xl font-bold'>Loading...</h1> : blogs?.map(blog => (
                                    <article key={blog.id} className='p-5 col-span-12 lg:col-span-6 bg-[#FDF4F3] rounded-[30px] flex flex-col gap-3'>
                                        <div className="pb-5">
                                            <img src={blog.image} className='w-full rounded-[30px] h-[260px]' alt={blog.title} />
                                        </div>
                                        <div>
                                            <h3 className='text-xl font-bold pb-3'>
                                                {blog.title} <span className='text-[#FE9901]'>{blog.subtitle}</span>
                                            </h3>
                                            <h6 className='text-[#212C65] text-sm font-bold pb-2'>{formatDate(blog.created_at)}</h6>
                                            <div className='text-justify' dangerouslySetInnerHTML={{ __html: getExcerpt(blog.desc, 225) }} />

                                        </div>
                                        <div className='mt-auto py-3'>
                                            <Link href={`/blogs/${blog.id}`} className='border-solid border-2 border-[#212C65] text-[#212C65] px-4 py-2 font-bold text-sm hover:bg-[#212C65] hover:text-white transition duration-150'>
                                                Read More
                                            </Link>
                                        </div>
                                    </article>
                                ))
                            }
                        </div>
                    </div>

                    <div className="col-span-12 lg:col-span-4 mx-auto">
                        <article className='bg-[#FEF4F3] p-10 rounded-[25px] border shadow-lg'>
                            <img src={Logo} className='w-44 mx-auto' alt="Logo" />
                            <div className="text-center py-4 text-[#212C65]">
                                <h2 className='text-xl font-bold'>Business Marketing Agency’s</h2>
                                <p className='text-sm font-bold tracking-widest'>OWNED BY YOU, DRIVEN BY US</p>
                            </div>
                            <div className="py-5 h-screen">
                                <ul className='font-bold text-sm uppercase flex flex-col gap-10 list-disc'>
                                    <li>Analytics and Measurement (3)</li>
                                    <li>Content Marketing (5)</li>
                                    <li>Local Advertising (5)</li>
                                    <li>Marketing Consultancy (8)</li>
                                    <li>Pay Per Click Advertising (5)</li>
                                    <li>Search Engine Optimization (11)</li>
                                    <li>Social Media Marketing (10)</li>
                                    <li>Website Design (3)</li>
                                </ul>
                            </div>
                        </article>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    );
};

export default BlogPage;
