/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Navbar from './Navbar';
import backgroundImage from '../../asset/image/background/headerBg.png';
import HeaderRightGroup from '../../asset/image/group/headerimage_group.png';
import { Typewriter } from 'react-simple-typewriter';

const Header = () => {

    const backgroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '70%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100%', // Adjust the height as needed
        width: '100%',
        margin: 'auto auto',
    };

    function onOpenModal() {
        // Load the external script when opening the modal
        const script = document.createElement('script');
        script.id = "__v_snapshot_widget";
        script.src = "https://www.cdnstyles.com/static/snapshot_widget/v1/snapshot.widget.js";
        script.setAttribute("data", "eyJhcGlLZXkiOiJkNzA0MzcwNi1mMWNjLTQ2MWUtODM1MC04MmRmMjI4NzdjM2QiLCJiYWNrZ3JvdW5kQ29sb3IiOiIjMWE3MmQwIiwiYm9yZGVyQ29sb3IiOiIjRkZGRkZGIiwiYm9yZGVyUmFkaXVzIjoyLCJidXR0b25Db2xvciI6IiNmZTk5MDEiLCJidXR0b25Gb250Q29sb3IiOiIjRkZGRkZGIiwiYnV0dG9uVGV4dCI6IkZyZWUgTWFya2V0aW5nIEFuYWx5c2lzIiwiY29uZmlybWF0aW9uQm9keVRleHQiOiJPbmNlIHRoZSByZXBvcnRzIGFyZSByZWFkeSwgd2UnbGwgc2VuZCB5b3VyIHJlcG9ydHMgdG8gdGhlIGVtYWlsIGFkZHJlc3MgeW91IGhhdmUgcHJvdmlkZWQuIiwiY29uZmlybWF0aW9uVGl0bGVUZXh0IjoiV2UncmUgZ2VuZXJhdGluZyB5b3VyIHJlcG9ydCEiLCJjb25zZW50SW5mb3JtYXRpb25UZXh0IjoiIiwiZGF0YVByb3RlY3Rpb25Qb2xpY3lUZXh0IjoiIiwiZHJvcFNoYWRvdyI6dHJ1ZSwiZW1haWxOb3RpZmljYXRpb25BZ3JlZW1lbnRUZXh0IjoiIiwicGFydG5lcklkIjoiRVowVSIsInByaW1hcnlGb250Q29sb3IiOiIjZmZmZmZmIiwic2Vjb25kYXJ5Rm9udENvbG9yIjoiI2ZmZmZmZiIsInRpdGxlVGV4dCI6IkhvdyBpcyBteSBidXNpbmVzcyBwZXJmb3JtaW5nIG9ubGluZT8iLCJ1cmwiOiJodHRwczovL3NuYXBzaG90LXdpZGdldC1wcm9kLmFwaWdhdGV3YXkuY28vIiwid2lkZ2V0SWQiOiJTTkFQU0hPVC1XSURHRVQtM2ZmZGNmYmYtNTQyNy00NGZkLWFkODItODg5YmU2MWE1ZGNjIiwid2lkdGgiOjB9");
        document.body.appendChild(script);
        document.getElementById('close').style = "display:block"
    }

    const closeModal = () => {
        window.location.reload();
        document.getElementById('close').style = "display:none"
    }


    return (
        <div style={backgroundStyle}>
            <Navbar />

            <div class="max-w-screen-2xl mx-auto p-4 grid grid-cols-12 gap-1 pt-40">

                <div class="p-4 col-span-12 lg:col-span-7">
                    <h2 class="text-3xl md:text-4xl lg:text-5xl font-bold py-2 leading-tight lg:leading-normal">
                        Affordable Digital Marketing  <br /> for &nbsp;
                        <span className='text-[#1877F2]'>
                            <Typewriter
                                loop={50}
                                cursor
                                typeSpeed={50}
                                deleteSpeed={40}
                                delaySpeed={500}
                                words={[
                                     'Real Estate Agencies',
                                     'Optometrists',
                                     'Massage therapists',
                                     'Wedding Photographers',
                                     'Electricians',
                                     'Lawyers',
                                     'Accountants',
                                     'Restaurants',
                                     'Solar Panel Companies',
                                     'Plumbers',
                                     'Roofing Companies',
                                     'Painters',
                                     'Cleaning Companies',
                                     'Wedding Planners',
                                     'Dentists',
                                    ]}
                            />
                        </span>
                    </h2>
                    <h6 class="text-xl md:text-2xl font-bold py-2 text-blue-900">
                        Starting from $97 / month
                    </h6>
                    <p class="text-lg md:text-xl py-2">
                       <b>Get More Local Customers with Our Affordable Digital Marketing  Services.</b> <br />  We help your business grow to attract more local customers  than <br />  your competitors.
                    </p>
                    <a href='#' onClick={onOpenModal} class="flex items-center justify-center gap-2 px-6 py-3 mt-6 text-white font-bold rounded-full transition w-fit mb-32 lg:mb-0 bg-[#1877F2] hover:bg-[#144c96]">
                        <span>Get Your Free Marketing Analysis</span>
                        <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                        </svg>
                    </a>

             
                </div>


                <div class="p-4 col-span-12 lg:col-span-5 flex justify-center lg:justify-end">
                    <img src={HeaderRightGroup} class="w-full h-auto max-w-lg" alt="Header Image" />
                </div>
            </div>


            <div className='max-w-screen-xl mx-auto grid-cols-1 md:grid-cols-1 shadow-gray-50 border-solid lg:pt-0 bg-slate-50 py-20' style={{ borderRadius: "28px" }}>
                <h3 className='text-3xl font-bold text-center lg:pt-20 pt-0'>Grow Your <span className='text-[#FE9901]'>REACH</span>, Grow Your <span className='text-[#FE9901]'>SALES</span> & Grow Your <span className='text-[#FE9901]'>BUSINESS</span></h3>
                <p className='text-center text-xl py-3'>Everything Local businesses need to succeed online </p>
            </div>

        </div>
    );
};

export default Header;