import React, { useState } from 'react';

const FrequentlyAskedQuestions = () => {
    const [activeIndex, setActiveIndex] = useState(null); // Start with all sections closed

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqs = [
        {
            question: "What does a digital marketing agency do?",
            answer: "Digital marketing is the most popular way of reaching out to potential clients interested in buying your product or service. It is also the most cost-effective way of doing so. Business Marketing Agency will help you to achieve more sales while at the same time increasing your brand awareness and establishing your business as a leader in its field."
        },
        {
            question: "Where is Business Marketing Agency located?",
            answer: "Our agency is centrally located in the heart of the business district, making it convenient for clients to visit us for in-person consultations. Our location also ensures we're at the pulse of market trends and innovations."
        },
        {
            question: "How can you help my business?",
            answer: "By leveraging the latest digital marketing strategies and tools, we help your business increase its online visibility, attract more targeted traffic, and convert that traffic into sales and long-term customers."
        },
        {
            question: "What are the benefits of digital marketing for my company?",
            answer: "Digital marketing offers measurable results, targeted audience engagement, and a higher return on investment compared to traditional marketing methods. It allows for global reach and the ability to easily adjust strategies based on analytics."
        },
        {
            question: "What do digital marketers do?",
            answer: "Digital marketers strategize and implement campaigns across various digital platforms. They analyze data to optimize strategy and improve performance, and create engaging content that speaks to the audience."
        },
    ];

    return (
        <div className='py-9 pb-20'>

            <div className="max-w-screen-2xl mx-auto px-3">
                <div className="text-center max-w-screen-lg mx-auto pt-20 pb-10">
                    <h2 className='text-4xl font-bold text-[#FE9901]'>Frequently Asked Questions</h2>
                </div>

                <div id="accordion-color" data-accordion="collapse" className='mx-5'>
                    {faqs.map((faq, index) => (
                        <div key={index} className="mb-4">
                            <h2 id={`accordion-color-heading-${index}`}>
                                <button
                                    type="button"
                                    className={`flex items-center justify-between w-full p-5 font-medium text-left border-gray-200 focus:outline-none ${activeIndex === index ? 'bg-cardBG text-white' : 'bg-[#F8F8F8] text-gray-800'} dark:border-gray-700 gap-3`}
                                    onClick={() => toggleAccordion(index)}
                                    style={{ borderRadius: activeIndex === index ? "25px 25px 0 0" : "25px" }}
                                >
                                    <span className='text-lg'>{faq.question}</span>
                                    <svg
                                        data-accordion-icon
                                        className={`w-6 h-6 transition-transform duration-200 ${activeIndex === index ? 'rotate-180' : ''}`}
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 9l6 6 6-6"
                                        />
                                    </svg>
                                </button>
                            </h2>
                            {activeIndex === index && (
                                <div id={`accordion-color-body-${index}`} className="p-5 pt-0 dark:border-gray-700 bg-cardBG text-white" style={{ borderRadius: "0 0 25px 25px" }}>
                                    <p className="mb-2 text-sm leading-[30px]">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
};

export default FrequentlyAskedQuestions;
